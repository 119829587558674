import { nextTick } from "vue"
import { useRouter } from "vue-router";
import { usePOSStore } from "@/views/pos/stores/pos";
import { usePaymentsStore } from "@/views/pos/stores/payments";
import { useInvoicesStore } from "@/views/pos/stores/invoices";
import { useCartStore } from "../stores/cart";
import { Modal } from "bootstrap";
import { useContentModalsStore } from "@/stores/content/modals";

const posStore = usePOSStore();
const paymentsStore = usePaymentsStore();
const invoiceStore = useInvoicesStore();
const cartStore = useCartStore();

export default function usePOS() {
  const router = useRouter();

  const initPOS = async (posId: number) => {
    await posStore.getPOS(posId);
  }

  const menuAction = async (menuItem) => {
    try {
      posStore.menuAction.isLoading = true;
      menuItem.isLoading = true;

      if (menuItem.session) {
        if (posStore.currentSessionId == null) {
          return;
        }
      }

      const { process, method, target } = menuItem.action;
      await posStore.processMenuAction(menuItem.action);

      if (process == "unlockPos") {
        await posStore.unlockPos(posStore.currentPOSId);
        posStore.$reset();
        router.push({ path: '/pos' })
      } else if (process === "createSale") {
        posStore.isLoading = true;
        const { data } = await posStore.createSale();

        try {
          await posStore.checkTaskStatus(data.task_id);
        }
        finally {
          if (data.id) {
            router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout/' + data.id })
          }
        }
      } else if (process === "lockUser") {
        await posStore.lockUser();
        router.push({ name: 'sign-in' })
      } else if (process === "openClose" || process === "return") {
        const contentModalStore = useContentModalsStore();

        contentModalStore.openModal(target, { type: 'modal', custom: true });

        // nextTick(() => {
        //   const modal = document.getElementById(target);
        //   const instance = Modal.getOrCreateInstance(modal);
        //   instance.toggle();
        // })
      } else if (process == "withdrawalDeposit") {
        router.push({ path: '/pos/sessions/' + posStore.currentSessionId + '/cash-history' })
      }

    } finally {
      posStore.menuAction.isLoading = false;
      menuItem.isLoading = false;
    }
  }

  // const transactionAction = (action) => {
  //   if (action.action === 'openReceipt') {
  //     if (invoiceStore.current.pdf_pos_receipt_url) {
  //       window.open(invoiceStore.current.pdf_pos_receipt_url, '_blank');
  //     }
  //   } else if (action.action === 'dpReceipt') {
  //     posStore.directPrint(action.action, invoiceStore.current?.pdf_pos_receipt_url);
  //   } else if (action.action === 'openInvoice') {
  //     if (invoiceStore.current.pdf_url) {
  //       window.open(invoiceStore.current.pdf_url, '_blank');
  //     }
  //   } else if (action.action === 'dpInvoice') {
  //     posStore.directPrint(action.action, invoiceStore.current?.pdf_url);
  //   }
  // }

  return {
    initPOS,
    menuAction
  }
}